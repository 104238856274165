.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  z-index: 1;
  user-select: none;
}

.App-side-logo {
  height: 1.6rem;
  pointer-events: none;
  z-index: 1;
  user-select: none;
}

.App-header {
  background: #000000;
  background-size: 200% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.play-now {
  color: #ffffff8b;
  z-index: 1;
  font-weight: 100;
  font-size: 0.7em;
  margin-bottom: 1.5rem;
  user-select: none;
}

.welcome {
  color: #ffffff;
  z-index: 1;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2rem;
  user-select: none;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0 0; /* Start position of the gradient */
  }
  50% {
    background-position: 100% 0; /* Middle position of the gradient */
  }
  100% {
    background-position: 0 0; /* End position of the gradient */
  }
}

.logo-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.languages-container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  gap: 1rem;
}

.support-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.low-img-container {
  position: absolute;
  bottom: 1rem;
  right: 0;
  width: 40rem;
}

.button-container {
  display: flex;
}

.button-item {
  margin-bottom: 0.5rem;
  margin-left: -3rem;
  margin-right: -3rem;
}

@media (max-width: 768px) {
  .button-container {
    display: block;
  }
  .low-img-container {
    width: 20rem;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
:root {
  --transition: 0.25s;
  --spark: 1.8s;
}
